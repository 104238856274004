<template>
    <div id="report-preview">
        <div
            v-if="!metricsLoading"
            id="render-complete" />
        <page-preview
            v-for="(page,p) in filteredPages"
            :key="p"
            :page="page"
            @add-page="addPage($event, p)"
            @loading="setPageLoading($event, p)" />
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import PagePreview from './PagePreview';
import moment from 'moment';
import InitMetricsMixin from '@/mixins/init-metrics';
import { SET_DATE_RANGE, UPDATE_FB_ATTRIBUTION, SET_FB_DEALER_CAMPAIGNS_SELECTED } from '@/store/mutation-types';

import TemplatesApi from '@/templates-api';

export default {
    name: 'ReportPreview',
    components: {
        PagePreview,
    },
    mixins: [InitMetricsMixin],
    data() {
        return {
            pagesLoading: [],
            errorOccurrred: false,
            error: {},
        };
    },
    computed: {
        ...mapState({
            metrics: state => state.metrics,
            template: (state) => state.report.template
        }),
        ...mapGetters(['dealerHasFeatures']),
        pages() {
            return this.template?.pages || [];
        },
        filteredPages() {
            return this.pages.filter( page => {
                // filter out Facebook inventory page if we don't have any inventory
                const facebookInventory = this.metrics?.facebook?.ads?.metricsInventory ?? [];
                if (page.meta.platform === 'Facebook' && page.slots[0].dataType === 'Inventory' && facebookInventory.length === 0) {
                    return false;
                }
                if(!page.meta.required_feature) {
                    return true;
                }
                return this.dealerHasFeatures([page.meta.required_feature]);
            });
        },
        ...mapGetters('metrics/facebook', {
            metricsLoading: 'loading'
        }),
        renderComplete() {
            return this.pagesLoading.every(loading => loading === false);
        }
    },
    async created() {
        this.$title = 'Report Preview';
        const dealerId = this.$route.query.dealer_id;
        this.updateDealerById(dealerId);

        // Get Report from API and set it in store
        this.TemplatesApi = new TemplatesApi();
        const templateId = this.$route.params.template_id;
        const template = await this.TemplatesApi.getTemplate(templateId);
        const config = Object.freeze(template.configuration);
        this.setTemplate(config);

        // Get date range and set it in the store
        //TODO: Add interval and attribution
        const startDate = moment(this.$route.query.start_date).toDate();
        const endDate = moment(this.$route.query.end_date).endOf('day').toDate();
        const dateRange = {
            startDate,
            endDate
        };
        // FIXME: Replace this with an action
        this.$store.commit(SET_DATE_RANGE, dateRange);

        const attributionWindow = decodeURIComponent(this.$route.query.attribution_window);

        if (attributionWindow) {
            const parsed = JSON.parse(attributionWindow);

            this.$store.commit(UPDATE_FB_ATTRIBUTION, parsed);
        }

        // @todo add the campaign ids to the store here
        let campaignIds = this.$route.query['facebook[campaigns][]'];
        if (campaignIds) {
            // Check if campaignIds is a string. This happens when its only one campaign
            if (typeof campaignIds === 'string') {
                campaignIds = [campaignIds];
            }

            // Map over the campaignIds and create an object with the id
            const selectedCampaigns = campaignIds.map(campaignId => {
                return {
                    id: campaignId,
                };
            });

            // Insert the selected campaigns into the store. The data will be used to filter the report
            this.$store.commit(SET_FB_DEALER_CAMPAIGNS_SELECTED, selectedCampaigns);
        }
    },
    methods: {
        ...mapActions([
            'updateDealerById',
            'setTemplate'
        ]),
        addPage(pagination, requestingPageIndex) {
            let newPage = JSON.parse(JSON.stringify(this.pages[requestingPageIndex]));
            newPage.slots[0].pagination = pagination;
            this.pages.splice(requestingPageIndex + 1, 0, newPage);
        },
        setPageLoading(pageLoading, pageIndex) {
            this.$set(this.pagesLoading, pageIndex, pageLoading);
        }
    }

};
</script>

<style lang="scss" scoped>
#render-complete {
    position: absolute;
    top: 0;
    left: 0;
    height: 50px;
    width: 50px;
    opacity: 0;
}
.no-data {
    width: 400px;
    padding: 15px;
    font-size: 16px;
    font-weight: 600;
    color: $carnation;
    background: $white;
    text-align:center;
    margin: 0 auto;
    margin-top: 60px;
}
.widget-meta {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .title {
        line-height: 1;
        margin-left: 15px;
        font-size: 18px !important;
        text-transform: uppercase;
        color: #6A7073;
    }

}
#facebook-advertising-funnel {
    .funnel {
        margin-top: 120px;
    }
}
#vehicle-performance {
    .widget-meta {
        margin-top: 0;
    }
}
#ready-to-upload {

    background-image: url('./assets/backgrounds/connecting_nodes.jpg');
    background-size: cover;
    padding: 30px;
    .container {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .heading {
        font-size: 50px;
        font-weight: 600;
        text-align: center;
        color: $white;
    }
    .sub-heading {
        color: $white;
        text-align: center;
        font-size: 24px;
        font-weight: 600;
    }
    .steps {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .step {
            margin: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .number {
                height: 60px;
                width: 60px;
                margin-bottom: 30px;
                color: $white;
                font-size: 40px;
                font-weight: 700;
                text-align: center;
                border-radius: 50%;
            }
            .illustration {
                height: 80px;
                width: auto;
                margin-bottom: 30px;
                svg {
                    height: 100%;
                    width: auto;
                }
            }
            .description {
                text-align: center;
                font-size: 20px;
                font-weight: 600;
            }
        }
    }
    .button {
        margin-top: 60px;
        text-decoration: none;
        font-size: 36px;
        font-weight: 600;
        color: $white;
        padding: 20px 45px;
        border-radius: 15px;
    }
}
#back-cover {
    background-image: url('./assets/backgrounds/connecting_nodes.jpg');
    background-size: cover;
    .dealer-info {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
        .logo {
            height: auto;
            width: 250px;
            img {
                height: 100%;
                width: 100%;
            }
        }
        .website {
            color: $white;
        }
    }
    .badge {
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        height: auto;
        width: 120px;
        img {
            height: 100%;
            width: 100%;
        }
    }
}

</style>
